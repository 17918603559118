import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class MarksEntryAllSubjectModel extends BaseModel {
  branch_code: FormField;
  academic_year: FormField;
  subject_register_id: FormField;
  assessment_id: FormField;
 
  constructor() {
    super();
    this.branch_code = new FormField("", true, [Validations.EMPTY]);
    this.academic_year = new FormField("", true, [Validations.EMPTY]);
    this.subject_register_id = new FormField("", true, [Validations.EMPTY]);
    this.assessment_id = new FormField("", true, [Validations.EMPTY]);


  }
}

export class MarksEntryAllSubjectEditModel extends BaseModel {
  student_id: FormField;
  student_name: FormField;
  department_name: FormField;
  classroom_name: FormField;
  section: FormField;
  previous_mark: FormField;
  marks: FormField;
  is_absent: FormField;
 
  constructor() {
    super();
    this.student_id = new FormField("", true, [Validations.EMPTY]);
    this.student_name = new FormField("", true, [Validations.EMPTY]);
    this.department_name = new FormField("", true, [Validations.EMPTY]);
    this.classroom_name = new FormField("", true, [Validations.EMPTY]);
    this.section = new FormField("", true, [Validations.EMPTY]);
    this.previous_mark = new FormField("", true, [Validations.EMPTY]);
    this.marks = new FormField("", true, [Validations.EMPTY]);
    this.is_absent = new FormField("", true, [Validations.EMPTY])
    this.valueChangeListner()
  }
  valueChangeListner(){
    this.marks.valueChangeListener().subscribe(values=>{
      // console.log(values)
      if((/^(ab)?$/i.test(values.newValue))){
        this.marks.validations.splice(0,this.marks.validations.length);
        this.marks.validations.push(Validations.EMPTY)
      }else{
        this.marks.validations.push(Validations.DECIMAL);
      }
  
    })
  }
}