import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class ParentLoginModel extends BaseModel{
  student_id: FormField;
  dateOfBirth: FormField;
  constructor() {
    super();
    this.student_id = new FormField("", true, [Validations.EMPTY]);
    this.dateOfBirth = new FormField("", true, [Validations.EMPTY, Validations.DATE]);
  }
}


export class StudentDetailModel extends BaseModel{
    student_name: FormField;
    father_name: FormField;
    mother_name: FormField;
    standard: FormField;
    section: FormField;
    department: FormField;
    academic_year: FormField;
    level_code: FormField;
    department_code: FormField;
    constructor() {
      super();
      this.student_name = new FormField("", false, [Validations.EMPTY]);
      this.father_name = new FormField("", false, [Validations.EMPTY]);
      this.mother_name = new FormField("", false, [Validations.EMPTY]);
      this.standard = new FormField("", false, [Validations.EMPTY]);
      this.section = new FormField("", false, [Validations.EMPTY]);
      this.department = new FormField("", false, [Validations.EMPTY]);
      this.academic_year = new FormField("", false, [Validations.EMPTY]);
      this.level_code = new FormField("", false, [Validations.EMPTY]);
      this.department_code = new FormField("", false, [Validations.EMPTY]);
    }
}

export class StudentFeeAmountDetailModel extends BaseModel{
    partialPayment: FormField;
    total_amount: FormField;
    total_concession_amount: FormField;
    payable_amount: FormField;
    pay_amount: FormField;
    constructor() {
        super();
        this.partialPayment = new FormField(false, false, [Validations.EMPTY]);
        this.total_amount = new FormField("", false, [Validations.EMPTY]);
        this.total_concession_amount = new FormField("", false, [Validations.EMPTY]);
        this.payable_amount = new FormField("", false, [Validations.EMPTY]);
        this.pay_amount = new FormField("", false, [Validations.EMPTY]);
    }
}

export class FinanceMdoel extends BaseModel{
  academic_year: FormField;
  branch_code: FormField;
  constructor() {
    super();
    this.academic_year = new FormField("", false, [Validations.EMPTY]);
    this.branch_code = new FormField("", false, [Validations.EMPTY]);
  }
}