import { FormField, FormService } from '../services/formService';
import { Validations } from '../services/validations';
import { BaseModel } from './baseModel';


export class TimeTableConfigModel extends BaseModel {
  branch_code: FormField;
  department_code: FormField;
  academic_year: FormField; 
  level_code: FormField;
  section:FormField;
  constructor() {
    super();
    this.branch_code = new FormField("", true, [Validations.EMPTY]);
    this.department_code = new FormField("", true, [Validations.EMPTY]);
    this.section = new FormField("", true, [Validations.EMPTY]);
    this.level_code = new FormField("", true, [Validations.EMPTY]);
    this.academic_year = new FormField("", true, [Validations.EMPTY]);
  }
}

export class TimeTableConfigEditModel extends BaseModel {
  to_day: FormField;
  start_time: FormField;
  end_time: FormField;
  min_period_duration: FormField;
  breaks: Array<BreakModel> = [];
  breaks_added: FormField;

  constructor(){
    super();
    this.to_day = new FormField("", true, [Validations.EMPTY]);
    this.start_time = new FormField("", true, [Validations.EMPTY,Validations.TIME12HOURS]);
    this.end_time = new FormField("", true, [Validations.EMPTY,Validations.TIME12HOURS]);
    this.min_period_duration = new FormField("", true, [Validations.EMPTY, Validations.NUMBER]);
    this.breaks_added = new FormField("", true, [Validations.EMPTY]);
    this.breaks = []
  }
}

export class BreakModel extends BaseModel {
  start_time: FormField;
  end_time: FormField;
  break_name: FormField;

  constructor() {
    super();
    this.start_time = new FormField("", true, [Validations.EMPTY,Validations.TIME12HOURS]);
    this.end_time = new FormField("", true, [Validations.EMPTY,Validations.TIME12HOURS]);
    this.break_name = new FormField("", true, [Validations.EMPTY]);
  }
}

export class BreakNumberModel extends BaseModel {
  total_break: FormField;

  constructor() {
    super();
    this.total_break = new FormField("", true, [Validations.EMPTY]);
  }
}