import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class newConfigModel extends BaseModel {
  branch_code_from: FormField;
  academic_year_from: FormField;
  department_code_from: FormField;
  branch_code_to: FormField;
  academic_year_to: FormField;
  department_code_to: FormField;
  student_name: FormField;
  student_id: FormField;
  section_from: FormField;
  section_to: FormField;
  selected_student_id: FormField;
  classroom_from: FormField;
  classroom_to: FormField;
  purpose: FormField

  constructor() {
    super();
    this.branch_code_from = new FormField("", true, [Validations.EMPTY]);
    this.classroom_from = new FormField("", true, [Validations.EMPTY]);
    this.selected_student_id = new FormField("", true, [Validations.EMPTY])
    this.academic_year_from = new FormField("", true, [Validations.EMPTY]);
    this.department_code_from = new FormField("", true, [Validations.EMPTY]);
    this.section_from = new FormField("", true, [Validations.EMPTY]);

    this.branch_code_to = new FormField("", true, [Validations.EMPTY]);
    this.classroom_to = new FormField("", true, [Validations.EMPTY]);
    this.selected_student_id = new FormField("", true, [Validations.EMPTY])
    this.academic_year_to = new FormField("", true, [Validations.EMPTY]);
    this.department_code_to = new FormField("", true, [Validations.EMPTY]);
    this.section_to = new FormField("", true, [Validations.EMPTY]);
  }
}
