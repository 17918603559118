import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class FeePaymentBasicModel extends BaseModel{
  branch_code: FormField;
  student_id: FormField;
  student_name: FormField;
  constructor() {
    super();
    this.branch_code = new FormField("", true, [Validations.EMPTY]);
    this.student_id = new FormField("", true, [Validations.EMPTY, Validations.SPACES]);
    this.student_name =  new FormField("", true, [Validations.EMPTY]);
  }
}

export class FeePaymentStudentDetailsModel extends BaseModel{
  student_name: FormField
  father_name: FormField;
  department_name: FormField;
  academic_year: FormField; 
  level_code: FormField;
  section: FormField;
  reservation_category: FormField;
  merit_student: FormField;
  constructor() {
    super();
    this.student_name =  new FormField("", true, [Validations.EMPTY]);
    this.father_name =  new FormField("", true, [Validations.EMPTY]);
    this.department_name =  new FormField("", true, [Validations.EMPTY]);
    this.level_code=  new FormField("", true, [Validations.EMPTY]);
    this.section = new FormField("", true, [Validations.EMPTY]);
    this.academic_year = new FormField("", true, [Validations.EMPTY]);
    this.reservation_category = new FormField("", true, [Validations.EMPTY]);
    this.merit_student = new FormField("", true, [Validations.EMPTY]);
  }
}

export class FeeDetailModel extends BaseModel{
  total_fee_assigned: FormField;
  total_fee_collected: FormField;
  concession_amount: FormField;
  amount_to_be_collected: FormField;
  constructor() {
    super();
    this.total_fee_assigned = new FormField("", true, [Validations.EMPTY]);
    this.total_fee_collected = new FormField("", true, [Validations.EMPTY]);
    this.concession_amount = new FormField("", true, [Validations.EMPTY]);
    this.amount_to_be_collected = new FormField("", true, [Validations.EMPTY]);
  }
}

export class FeePaymentDetailsModel extends BaseModel{
  payment_mode: FormField;
  paid_on: FormField;
  amountpaid: FormField;
  challan_no: FormField;
  reference_no: FormField;
  reference_date: FormField;
  due_date: FormField;
  constructor() {
    super();
    this.payment_mode = new FormField("", true, [Validations.EMPTY]);
    this.paid_on = new FormField("", true, [Validations.EMPTY]);
    this.amountpaid = new FormField("", true, [Validations.EMPTY,Validations.DECIMAL]);
    this.challan_no = new FormField("", true, [Validations.EMPTY]);
    this.reference_no = new FormField("", false);
    this.reference_date = new FormField("", false,[]);
    this.due_date = new FormField("", false,[Validations.DATE]);
  }
}

export class FeePaymentBankDetailsModel extends BaseModel{
  bank_name:FormField;
  bank_branch:FormField;
  cheque_no: FormField;
  date_of_transaction: FormField;
  constructor() {
    super();
    this.bank_name = new FormField("",false);
    this.bank_branch = new FormField("",false);
    this.cheque_no = new FormField("", true, [Validations.EMPTY]);
    this.date_of_transaction = new FormField("", true, [Validations.EMPTY]);
  }
}