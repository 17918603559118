import { FormField, FormService } from '../services/formService';
import { Validations } from '../services/validations';
import { BaseModel } from './baseModel';

export class TestAllotmentModel extends BaseModel {
  branch_code: FormField;
  academic_year: FormField; 
  constructor() {
    super();
    this.branch_code = new FormField("", true, [Validations.EMPTY]);
    this.academic_year = new FormField("", true, [Validations.EMPTY]);
  }
}

export class TestAllotmentAddModel extends BaseModel {
  subject_id: FormField;
  subject_name: FormField;
  assessment_formfield: FormField;
  assessments: Array<any> = []
  constructor() {
    super();
    this.subject_id = new FormField("", true, [Validations.EMPTY]);
    this.subject_name = new FormField("", true, [Validations.EMPTY]);
    this.assessment_formfield = new FormField("", true, [Validations.EMPTY]);
    this.assessments = []
  }
}

export class TestAllotmentViewModel extends BaseModel {
  subject_id: FormField;
  subject_name: FormField;
  assessment_formfield: FormField;
  constructor() {
    super();
    this.subject_id = new FormField("", true, [Validations.EMPTY]);
    this.subject_name = new FormField("", true, [Validations.EMPTY]);
    this.assessment_formfield = new FormField("", true, [Validations.EMPTY]);
  }
}


