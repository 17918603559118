import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';
import { extend } from 'webdriver-js-extender';
 
export class GroupSubjectListModel extends BaseModel{
   branch_code: FormField;
   constructor() {
       super();
       this.branch_code = new FormField("", true, [Validations.EMPTY]);
   }
}
 
export class GroupSubjectAddModel extends BaseModel{
   group_name: FormField;
   choice: FormField;
   no_of_choice: FormField;
   subjects: Array<SubjectAddModel> = []
   constructor() {
       super();
       this.group_name = new FormField("", true, [Validations.EMPTY]);
       this.choice = new FormField(false, true, [Validations.EMPTY]);
       this.no_of_choice = new FormField("0", true);
       this.subjects = []
       this.valueChangeListner()
   }

   valueChangeListner(){
       this.choice.valueChangeListener().subscribe(values =>{
        //    console.log(values)
           if(values.oldValue != values.newValue){
               if(values.newValue){
                   this.no_of_choice.validations = [Validations.EMPTY, Validations.NUMBER]
               }else{
                this.no_of_choice.validations = []
               }
           }
       })
   }
}

export class SubjectAddModel extends BaseModel{
    subject_name: FormField;
    subject_code: FormField;
    subject_type: FormField;
    group_id: FormField;
    is_elective: FormField;
    has_lab: FormField;
    constructor() {
        super();
        this.subject_name = new FormField("", true, [Validations.EMPTY]);
        this.subject_code = new FormField("", true, [Validations.EMPTY]);
        this.subject_type = new FormField("THEORY", true, [Validations.EMPTY]);
        this.group_id = new FormField("0", true, [Validations.EMPTY]);
        this.has_lab = new FormField(false, true, [Validations.EMPTY]);
        this.is_elective = new FormField(false, false);
        this.checkSubjectType()
    }
    checkSubjectType(){
        this.subject_type.valueChangeListener().subscribe(values=>{
            if(values.oldValue != values.newValue){
                if(values.newValue == "LAB"){
                    this.has_lab.disable();
                    this.has_lab.value = false
                    this.has_lab.valueChanged()
                }else{
                    this.has_lab.enable();
                }
            }
        })
    }
 }

// export class FeeBreakupDetailModel extends BaseModel {
//     fee_breakup_desc: FormField;
//     fee_breakup_amount: FormField;
//     constructor() {
//       super();
//       this.fee_breakup_desc = new FormField(true, true, [Validations.EMPTY]);
//       this.fee_breakup_amount = new FormField(true, true, [Validations.EMPTY,Validations.DECIMAL]);
//     }
// }

// export class AuthorizationFeetypeModel extends BaseModel{
//     branch: FormField;
//     requested_by: FormField;
//     requested_on: FormField;
//     constructor() {
//         super();
//         this.branch = new FormField("", false, [Validations.EMPTY]);
//         this.requested_by = new FormField("", false, [Validations.EMPTY]);
//         this.requested_on = new FormField("", false, [Validations.EMPTY]);
//     }
// }


// export class ModifyFeeTypeAddModel extends BaseModel{
//     feeTypeName: FormField;
//     feeAmount: FormField;
//     optional: FormField;
//     multiple_type: FormField;
//     optionalFees: Array<ModifyOptionalFeeModel>;
//     diverse: boolean;
//     account: FormField;
//     fee_breakup_required: FormField;
//     fee_type_breakup_total_amount: FormField;
//     fee_type_breakup_added: FormField
//     fee_breakup_detail: Array<ModifyFeeBreakupDetailModel>;
  
//     constructor() {
//         super();
//         this.feeTypeName = new FormField("", true, [Validations.EMPTY]);
//         this.feeAmount = new FormField("", false, [Validations.EMPTY, Validations.DECIMAL]);
//         this.optional = new FormField(false, false);
//         this.multiple_type = new FormField(false, false);
//         this.optionalFees = [];
//         this.diverse = false;
//         this.account = new FormField("", true, [Validations.EMPTY]);
//         this.fee_breakup_required = new FormField(false, false);
//         this.fee_type_breakup_total_amount = new FormField(0, true, [Validations.EMPTY]);
//         this.fee_type_breakup_added = new FormField(0, true, [Validations.EMPTY]);
//         this.fee_breakup_detail = [];
//         this.valueChangeListner()
//     }
  
//     valueChangeListner(){
//         this.multiple_type.valueChangeListener().subscribe(values=>{
//             console.log(values)
//             if(values.oldValue != values.newValue){
//                 if(values.newValue){
//                      this.feeAmount.reset()
//                     this.feeAmount.disable()
//                     var optionalModel = new ModifyOptionalFeeModel()
//                     optionalModel.reset()
//                     this.optionalFees.push(optionalModel)
//                 }else if(!values.newValue){
//                      this.feeAmount.enable()
//                     this.optionalFees = []
//                     this.feeAmount.enable();
//                 }
//             }
//         })

//         this.fee_breakup_required.valueChangeListener().subscribe(values=>{
//             console.log(values)
//             if(values.oldValue != values.newValue){
//                 if(values.newValue){
//                     this.optionalFees = []
//                     this.multiple_type.disable()
//                     this.fee_type_breakup_total_amount.disable()
//                     this.fee_type_breakup_added.disable()
//                 }else if(!values.newValue){
//                     this.multiple_type.enable()
//                     this.optionalFees = []
//                     this.fee_breakup_detail = []
//                 }
//             }
//         })
//     }
//  }
  
//  export class ModifyOptionalFeeModel extends BaseModel{
//     id: FormField;
//     feeName: FormField;
//     feeAmount: FormField;
  
//     constructor() {
//         super();
//         this.id = new FormField("", true, [Validations.EMPTY]);
//         this.feeName = new FormField("", true, [Validations.EMPTY]);
//         this.feeAmount = new FormField("", true, [Validations.EMPTY, Validations.DECIMAL]);
//     }
  
// }

// export class ModifyFeeBreakupDetailModel extends BaseModel {
//     fee_breakup_id: FormField;
//     fee_breakup_desc: FormField;
//     fee_breakup_amount: FormField;
//     constructor() {
//       super();
//       this.fee_breakup_id = new FormField("", true, [Validations.EMPTY]);
//       this.fee_breakup_desc = new FormField(true, true, [Validations.EMPTY]);
//       this.fee_breakup_amount = new FormField(true, true, [Validations.EMPTY,Validations.DECIMAL]);
//     }
// }
 
