
import { of as observableOf, Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class EventSesrvice {
  public getEvents(): Observable<any> {
    const dateObj = new Date();
    const yearMonth = dateObj.getUTCFullYear() + '-' + (dateObj.getUTCMonth() + 1);
    let data: any = [{
      title: 'Holiday',
      start: yearMonth + '-01',
      color: '#ff9f1a'
    },
    {
      title: 'Dewali Holiday',
      start: yearMonth + '-05',
      end: yearMonth + '-07',
      color: '#ff9f1a'
    },
    {
      title: 'test1',
      start: yearMonth + '-15',
      end: yearMonth + '-16',
      color: '#2ecc71'
    },
    {
      title: 'test2',
      start: yearMonth + '-16',
      end: yearMonth + '-17',
      color: '#2ecc71'
    },
    {
      title: 'test3',
      start: yearMonth + '-17',
      end: yearMonth + '-18',
      color: '#2ecc71'
    },
      // {
      //   id: 999,
      //   title: 'Repeating Event',
      //   start: yearMonth + '-09T16:00:00'
      // },
      // {
      //   id: 999,
      //   title: 'Repeating Event',
      //   start: yearMonth + '-16T16:00:00'
      // },
      // {
      //   title: 'Conference',
      //   start: yearMonth + '-11',
      //   end: yearMonth + '-13'
      // },
      // {
      //   title: 'Meeting',
      //   start: yearMonth + '-12T10:30:00',
      //   end: yearMonth + '-12T12:30:00'
      // },
      // {
      //   title: 'Lunch',
      //   start: yearMonth + '-12T12:00:00'
      // },
      // {
      //   title: 'Meeting',
      //   start: yearMonth + '-12T14:30:00'
      // },
      // {
      //   title: 'Happy Hour',
      //   start: yearMonth + '-12T17:30:00'
      // },
      // {
      //   title: 'Dinner',
      //   start: yearMonth + '-12T20:00:00'
      // },
      // {
      //   title: 'Birthday Party',
      //   start: yearMonth + '-13T07:00:00'
      // },
      // {
      //   title: 'Click for Google',
      //   url: 'http://google.com/',
      //   start: yearMonth + '-28'
      // }
    ];

    return observableOf(data);
  }
};
