import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class SuperAdminMultiSelect extends BaseModel {
  branch_name: FormField;
  multiSelectForm: FormField;
  student_report_headers: Array<any> = []
  constructor() {
    super();
    this.branch_name = new FormField("", true, [Validations.EMPTY]);
    this.multiSelectForm = new FormField("", true);
    this.student_report_headers = []
  }
}
