import { FormField, FormService } from '../services/formService';
import { Validations } from '../services/validations';
import { BaseModel } from './baseModel';

export class FeeBreakupModel extends BaseModel {
  fee_type_id: FormField;
  fee_type_amount: FormField;
  fee_type_breakup_total_amount: FormField;
  fee_type_breakup_added: FormField
  fee_breakup_detail: Array<FeeBreakupDetailModel>;
  fee_type_drop_down: Array<any>;
  constructor() {
    super();
    this.fee_type_id = new FormField("", true, [Validations.EMPTY]);
    this.fee_type_amount = new FormField("Rs0", true, [Validations.EMPTY]);
    this.fee_type_breakup_total_amount = new FormField("Rs0", true, [Validations.EMPTY]);
    this.fee_type_breakup_added = new FormField(0, true, [Validations.EMPTY]);
    this.fee_breakup_detail = [];
    this.fee_type_drop_down = [];
  }
  
  addFeeBreakupDetail(fee_breakup_detail?: FeeBreakupDetailModel) {
    if (typeof fee_breakup_detail !== "undefined") {
      this.fee_breakup_detail.push(fee_breakup_detail);
    } else {
      this.fee_breakup_detail.push(new FeeBreakupDetailModel());
    }
  }
  removeScreen(index) {
    this.fee_breakup_detail.splice(index, 1);
  }
}

export class FeeBreakupDetailModel extends BaseModel {
  fee_breakup_desc: FormField;
  fee_breakup_amount: FormField;
  constructor() {
    super();
    this.fee_breakup_desc = new FormField(true, true, [Validations.EMPTY]);
    this.fee_breakup_amount = new FormField(true, true, [Validations.EMPTY]);
  }
}


export class FeeBreakupViewModel extends BaseModel {
  fee_type_name: FormField;
  fee_type_amount: FormField;
  fee_type_breakup_total_amount: FormField;
  fee_type_breakup_added: FormField
  fee_breakup_detail: Array<FeeBreakupDetailModel>;
  fee_type_drop_down: Array<any>;
  constructor() {
    super();
    this.fee_type_name = new FormField("", true, [Validations.EMPTY]);
    this.fee_type_amount = new FormField("Rs0", true, [Validations.EMPTY]);
    this.fee_type_breakup_total_amount = new FormField("Rs0", true, [Validations.EMPTY]);
    this.fee_breakup_detail = [];
  }
}

export class FeeBreakupDetailViewModel extends BaseModel {
  fee_breakup_desc: FormField;
  fee_breakup_amount: FormField;
  constructor() {
    super();
    this.fee_breakup_desc = new FormField(true, true, [Validations.EMPTY]);
    this.fee_breakup_amount = new FormField(true, true, [Validations.EMPTY]);
  }
}
