import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class FeedBackModel extends BaseModel {
  student_id: FormField;
  student_name: FormField;
  gender: FormField;
  Programme: FormField;
  semester: FormField;
  teacher_name: FormField;
  subject: FormField;
  suggestion: FormField;
  constructor() {
    super();
    this.student_id = new FormField("", true, [Validations.EMPTY]);
    this.student_name = new FormField("", true, [Validations.EMPTY]);
    this.gender = new FormField("", true, [Validations.EMPTY]);
    this.Programme = new FormField("", true, [Validations.EMPTY]);
    this.semester = new FormField("", true, [Validations.EMPTY]);
    this.teacher_name = new FormField("", true, [Validations.EMPTY]);
    this.subject = new FormField("", true, [Validations.EMPTY]);
    this.suggestion = new FormField("", true, [Validations.EMPTY]);
  }
}


