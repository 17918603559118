import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class FeeStructureBasicModel extends BaseModel {
  branch_code: FormField;
  academic_year: FormField;
  constructor() {
    super()
    this.branch_code = new FormField("", true, [Validations.EMPTY])
    this.academic_year = new FormField("", true, [Validations.EMPTY])
  }
}

export class FeeStructureAddModel extends BaseModel {
    fee_structure_name: FormField;
    amount: FormField;
    maximum: FormField;
    minimum: FormField;
    level_form: FormField;
    level: Array<any> = []
    fee_type: Array<FeeTypeAddModel> = []
    range_required: FormField;
    student_type: FormField;
    constructor() {
      super()
      this.fee_structure_name = new FormField("", true, [Validations.EMPTY]);
      this.amount = new FormField("", true, [Validations.EMPTY,Validations.DECIMAL]);
      this.minimum = new FormField("", true);
      this.level_form = new FormField("", true, [Validations.EMPTY])
      this.fee_type = []
      this.level = []
      this.range_required = new FormField(false, true, [Validations.EMPTY])
      this.student_type = new FormField("", true, [Validations.EMPTY])
    }

    variableChangeListner(i){
      this.fee_type[i].variable.valueChangeListener().subscribe(values =>{
        // console.log(values)
        if(values.oldValue != values.newValue){
          if(values.newValue){
            this.fee_type[i].amount.reset();
            this.fee_type[i].fee_type_breakups = []
            this.fee_type[i].amount.value = 0
            this.fee_type[i].amount.disable();
            this.disableAllVariable(i)
          }else{
            this.enableAllVariable(i)
          }
        }
      })
    }

    disableAllVariable(i){
      for(var j=0;j<this.fee_type.length;j++){
        if(i != j){
          this.fee_type[j].variable.disable()
        }
      }
    }

    enableAllVariable(i){
      for(var k=0;k<this.fee_type.length;k++){
        if( i == k){
          this.fee_type[k].amount.enable();
        }
        this.fee_type[k].variable.enable()
      }
    }
}

export class FeeTypeAddModel extends BaseModel {
    fee_type_name: FormField;
    amount: FormField;
    account: FormField;
    variable: FormField;
    fee_type_breakups: Array<FeeTypeBreakupAddModel> = []
    constructor() {
      super()
      this.fee_type_name = new FormField("", true, [Validations.EMPTY])
      this.amount = new FormField("", true, [Validations.EMPTY,Validations.DECIMAL])
      this.account = new FormField("", true, [Validations.EMPTY])
      this.variable = new FormField(false, true, [Validations.EMPTY])
    }
}

export class FeeTypeBreakupAddModel extends BaseModel {
    fee_breakup_name: FormField;
    amount: FormField;
    constructor() {
      super()
      this.fee_breakup_name = new FormField("", true, [Validations.EMPTY])
      this.amount = new FormField("", true, [Validations.EMPTY])
    }
}

export class FeeStructureViewModel extends BaseModel {
  fee_structure_name: FormField;
  amount: FormField;
  maximum: FormField;
  minimum: FormField;
  level_name: FormField;
  department_name: FormField;
  fee_type: Array<FeeTypeAddModel> = []
  range_required: FormField;
  student_type: FormField;
  constructor() {
    super()
    this.fee_structure_name = new FormField("", true, [Validations.EMPTY]);
    this.amount = new FormField("", true, [Validations.EMPTY,Validations.DECIMAL]);
    this.minimum = new FormField("", true);
    this.level_name = new FormField("", true, [Validations.EMPTY])
    this.department_name = new FormField("", true, [Validations.EMPTY])
    this.fee_type = []
    this.range_required = new FormField(false, true, [Validations.EMPTY])
    this.student_type = new FormField("", true, [Validations.EMPTY])
  }
}
