import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class NewConcessionModel extends BaseModel{
  concession_name: FormField;
  concession_type: FormField;
  value: FormField;
  attribute: FormField;
  merit_value: FormField;
  selected_category: Array<any> = []
  email_id: FormField;
  concession_form: FormField;
  approval_required: FormField;
  fee_type: FormField;
  fee_structure: FormField;
  particular: FormField;
  constructor() {
    super();
    this.concession_name = new FormField("", true, [Validations.EMPTY]);
    this.concession_type = new FormField("", true, [Validations.EMPTY]);
    this.value = new FormField("", true, [Validations.EMPTY, Validations.DECIMAL]);
    this.attribute = new FormField("", false,[Validations.EMPTY]);
    this.merit_value = new FormField("", false);
    this.email_id = new FormField("", false);
    this.selected_category = []
    this.concession_form = new FormField("", false);
    this.approval_required = new FormField(false,false)
    this.particular = new FormField(true, true, [Validations.EMPTY])
    this.fee_type = new FormField("", false);
    this.fee_structure = new FormField("", false);
    this.valueChangeListner()
  }

  valueChangeListner(){
    this.particular.valueChangeListener().subscribe(values =>{
      if(values.oldValue != values.newValue){
        // console.log(values)
        if(!values.newValue){
          this.fee_type.reset()
          this.fee_type.validations = []
        }else{
          this.fee_type.validations = [Validations.EMPTY]
        }
      }
    })
  }

  isRequired(){
    return this.particular.value
  }
}

export class NewConcessionModifyModel extends BaseModel{
  concession_name: FormField;
  concession_type: FormField;
  value: FormField;
  attribute: FormField;
  merit_value: FormField;
  selected_category: FormField
  email_id: FormField;
  approval_required: FormField;
  particular: FormField;
  fee_type: FormField;
  fee_structure: FormField;
  constructor() {
    super();
    this.concession_name = new FormField("", true, [Validations.EMPTY]);
    this.concession_type = new FormField("", true, [Validations.EMPTY]);
    this.value = new FormField("", true, [Validations.EMPTY, Validations.DECIMAL]);
    this.attribute = new FormField("", true, [Validations.EMPTY]);
    this.merit_value = new FormField("", false, [Validations.EMPTY, Validations.DECIMAL]);
    this.email_id = new FormField("", false, [Validations.EMPTY, Validations.EMAIL]);
    this.selected_category = new FormField("", false, [Validations.EMPTY]);
    this.approval_required = new FormField(false,false)
    this.particular = new FormField(true, true, [Validations.EMPTY])
    this.fee_type = new FormField("", false, [Validations.EMPTY]);
    this.fee_structure = new FormField("", false);

  }
}
