import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';
 
export class SubjectRegisterModel extends BaseModel{
    academic_year: FormField;
    branch_code: FormField;
    department_code: FormField;
    subject_id: FormField;
    constructor() {
        super();
        this.branch_code = new FormField("", true, [Validations.EMPTY]);
        this.academic_year = new FormField("", true, [Validations.EMPTY]);
        this.department_code = new FormField("0", true, [Validations.EMPTY]);
        this.subject_id = new FormField("0", true, [Validations.EMPTY]);
    }
}

export class SubjectRegisterAddModel extends BaseModel{
    create_type: FormField;
    level_form: FormField;
    register_name: FormField;
    constructor() {
        super();
        this.create_type = new FormField("AUTO", true, [Validations.EMPTY]);
        this.level_form = new FormField("", true, [Validations.EMPTY]);
        this.register_name = new FormField("", true, [Validations.EMPTY]);
        // this.subject_id = new FormField("0", true, [Validations.EMPTY]);
    }
}