import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class AttendanceScreenModel extends BaseModel {
  branch_code: FormField;
  subject_register_id: FormField;
  attendanceType:FormField;
  date: FormField;

  constructor() {
    super();
    this.branch_code = new FormField("", true, [Validations.EMPTY]);
    this.attendanceType =new FormField("", true, [Validations.EMPTY]);
    this.subject_register_id = new FormField("", true, [Validations.EMPTY]);
    this.date = new FormField("", true, [Validations.EMPTY, Validations.DATE]);

  }
}