import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';
import { extend } from 'webdriver-js-extender';
 
export class FeeTypeAddModel extends BaseModel{
   feeTypeName: FormField;
   feeAmount: FormField;
   optional: FormField;
   multiple_type: FormField;
   optionalFees: Array<OptionalFeeModel>;
   diverse: boolean;
   account: FormField;
   fee_breakup_required: FormField;
   fee_type_breakup_total_amount: FormField;
   fee_type_breakup_added: FormField
   fee_breakup_detail: Array<FeeBreakupDetailModel>;
   constructor() {
       super();
       this.feeTypeName = new FormField("", true, [Validations.EMPTY]);
       this.feeAmount = new FormField("", false, [Validations.EMPTY, Validations.DECIMAL]);
       this.optional = new FormField(false, false);
       this.multiple_type = new FormField(false, false);
       this.optionalFees = [];
       this.diverse = false;
       this.account = new FormField("", true, [Validations.EMPTY]);
       this.fee_breakup_required = new FormField(false, false);
       this.fee_type_breakup_total_amount = new FormField(0, true, [Validations.EMPTY]);
        this.fee_type_breakup_added = new FormField(0, true, [Validations.EMPTY]);
        this.fee_breakup_detail = [];
       this.valueChangeListner()
   }
   
    addFeeBreakupDetail(fee_breakup_detail?: FeeBreakupDetailModel) {
        if (typeof fee_breakup_detail !== "undefined") {
            this.fee_breakup_detail.push(fee_breakup_detail);
        } else {
            var break_up = new FeeBreakupDetailModel()
            this.fee_breakup_detail.push(break_up);
        }
    }

    removeScreen(index) {
        this.fee_breakup_detail.splice(index, 1);
    }

    valueChangeListner(){
       this.multiple_type.valueChangeListener().subscribe(values=>{
        //    console.log(values)
           if(values.oldValue != values.newValue){
               if(values.newValue){
                    this.feeAmount.reset()
                   this.feeAmount.disable()
                   var optionalModel = new OptionalFeeModel()
                   optionalModel.reset()
                   this.optionalFees.push(optionalModel)
                   this.fee_breakup_detail = []
                   this.fee_breakup_required.disable()
               }else if(!values.newValue){
                    this.feeAmount.enable()
                   this.optionalFees = []
                   this.feeAmount.enable();
                   this.fee_breakup_detail = []
                   this.fee_breakup_required.enable()
               }
           }
       })


       this.fee_breakup_required.valueChangeListener().subscribe(values=>{
        // console.log(values)
        if(values.oldValue != values.newValue){
            if(values.newValue){
                this.optionalFees = []
                this.multiple_type.disable()
                this.fee_type_breakup_total_amount.disable()
                this.fee_type_breakup_added.disable()
            }else if(!values.newValue){
                this.multiple_type.enable()
                this.optionalFees = []
                this.fee_breakup_detail = []
            }
        }
    })
   }
}
 
export class OptionalFeeModel extends BaseModel{
   feeName: FormField;
   feeAmount: FormField;
 
   constructor() {
       super();
       this.feeName = new FormField("", true, [Validations.EMPTY]);
       this.feeAmount = new FormField("", true, [Validations.EMPTY, Validations.DECIMAL]);
   }
 
}

export class FeeBreakupDetailModel extends BaseModel {
    fee_breakup_desc: FormField;
    fee_breakup_amount: FormField;
    constructor() {
      super();
      this.fee_breakup_desc = new FormField(true, true, [Validations.EMPTY]);
      this.fee_breakup_amount = new FormField(true, true, [Validations.EMPTY,Validations.DECIMAL]);
    }
}

export class AuthorizationFeetypeModel extends BaseModel{
    branch: FormField;
    requested_by: FormField;
    requested_on: FormField;
    constructor() {
        super();
        this.branch = new FormField("", false, [Validations.EMPTY]);
        this.requested_by = new FormField("", false, [Validations.EMPTY]);
        this.requested_on = new FormField("", false, [Validations.EMPTY]);
    }
}


export class ModifyFeeTypeAddModel extends BaseModel{
    feeTypeName: FormField;
    feeAmount: FormField;
    optional: FormField;
    multiple_type: FormField;
    optionalFees: Array<ModifyOptionalFeeModel>;
    diverse: boolean;
    account: FormField;
    fee_breakup_required: FormField;
    fee_type_breakup_total_amount: FormField;
    fee_type_breakup_added: FormField
    fee_breakup_detail: Array<ModifyFeeBreakupDetailModel>;
  
    constructor() {
        super();
        this.feeTypeName = new FormField("", true, [Validations.EMPTY]);
        this.feeAmount = new FormField("", false, [Validations.EMPTY, Validations.DECIMAL]);
        this.optional = new FormField(false, false);
        this.multiple_type = new FormField(false, false);
        this.optionalFees = [];
        this.diverse = false;
        this.account = new FormField("", true, [Validations.EMPTY]);
        this.fee_breakup_required = new FormField(false, false);
        this.fee_type_breakup_total_amount = new FormField(0, true, [Validations.EMPTY]);
        this.fee_type_breakup_added = new FormField(0, true, [Validations.EMPTY]);
        this.fee_breakup_detail = [];
        this.valueChangeListner()
    }
  
    valueChangeListner(){
        this.multiple_type.valueChangeListener().subscribe(values=>{
            // console.log(values)
            if(values.oldValue != values.newValue){
                if(values.newValue){
                     this.feeAmount.reset()
                    this.feeAmount.disable()
                    var optionalModel = new ModifyOptionalFeeModel()
                    optionalModel.reset()
                    this.optionalFees.push(optionalModel)
                }else if(!values.newValue){
                     this.feeAmount.enable()
                    this.optionalFees = []
                    this.feeAmount.enable();
                }
            }
        })

        this.fee_breakup_required.valueChangeListener().subscribe(values=>{
            // console.log(values)
            if(values.oldValue != values.newValue){
                if(values.newValue){
                    this.optionalFees = []
                    this.multiple_type.disable()
                    this.fee_type_breakup_total_amount.disable()
                    this.fee_type_breakup_added.disable()
                }else if(!values.newValue){
                    this.multiple_type.enable()
                    this.optionalFees = []
                    this.fee_breakup_detail = []
                }
            }
        })
    }
 }
  
 export class ModifyOptionalFeeModel extends BaseModel{
    id: FormField;
    feeName: FormField;
    feeAmount: FormField;
  
    constructor() {
        super();
        this.id = new FormField("", true, [Validations.EMPTY]);
        this.feeName = new FormField("", true, [Validations.EMPTY]);
        this.feeAmount = new FormField("", true, [Validations.EMPTY, Validations.DECIMAL]);
    }
  
}

export class ModifyFeeBreakupDetailModel extends BaseModel {
    fee_breakup_id: FormField;
    fee_breakup_desc: FormField;
    fee_breakup_amount: FormField;
    constructor() {
      super();
      this.fee_breakup_id = new FormField("", true, [Validations.EMPTY]);
      this.fee_breakup_desc = new FormField(true, true, [Validations.EMPTY]);
      this.fee_breakup_amount = new FormField(true, true, [Validations.EMPTY,Validations.DECIMAL]);
    }
}
 
