import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class ChallanModel extends BaseModel{
  branch_code: FormField;
  student_id: FormField;
  student_name: FormField;
  father_name: FormField;
  level_code: FormField;
  academic_year: FormField; 
  section: FormField;
  challan_no: FormField;
  breakup: Array<FeeBreakupModel>;
  paid_date: FormField;
  cheque_no: FormField;
  cash: FormField;
  bankName:FormField;
  bankBranch:FormField;
  amountPaid: FormField;
  reservation_category: FormField;
  merit_student: FormField;
  mother_name: FormField;
  constructor() {
    super();
    this.breakup = [];
    this.branch_code = new FormField("", true, [Validations.EMPTY]);
    this.student_id = new FormField("", true, [Validations.EMPTY, Validations.SPACES]);
    this.student_name =  new FormField("", true, [Validations.EMPTY]);
    this.father_name =  new FormField("", true, [Validations.EMPTY]);
    this.level_code=  new FormField("", true, [Validations.EMPTY]);
    this.section = new FormField("", true, [Validations.EMPTY]);
    this.academic_year = new FormField("", true, [Validations.EMPTY]);
    this.challan_no = new FormField("", true, [Validations.EMPTY]);
    this.paid_date = new  FormField("", true, [Validations.EMPTY]);
    this.cheque_no = new FormField("", true, [Validations.EMPTY]);
    this.cash = new FormField();
    this.amountPaid = new FormField("", true, [Validations.EMPTY]);
    this.bankName = new FormField();
    this.bankBranch = new FormField();
    this.reservation_category = new FormField("", true, [Validations.EMPTY]);
    this.merit_student = new FormField("", true, [Validations.EMPTY]);
    this.mother_name =  new FormField("", true);
  }
}

export class FeeBreakupModel extends BaseModel{
  fee_type: FormField;
  breakups: Array<FormField>;
  constructor(){
    super();
    this.fee_type = new FormField("", true, [Validations.EMPTY]);
  }

   addFeeBreakupDetail(...args: any[]) {
   this.breakups.push(new FormField("", true, [Validations.EMPTY]));
   if (typeof args[0] !== "undefined") {
      this.breakups[this.breakups.length - 1].value = args[0];
    }
  }

}