import { FormField, FormService } from '../services/formService';
import { Validations } from '../services/validations';
import { BaseModel } from './baseModel';

export class GroupModel extends BaseModel{
  group_name: FormField;
  is_active: FormField;

  constructor() {
    super();
    this.group_name = new FormField("", true, [Validations.EMPTY]);
    this.is_active = new FormField(true, true, [Validations.EMPTY]);

  }

}
