import { FormField, FormService } from '../services/formService';
import { Validations } from '../services/validations';
import { BaseModel } from './baseModel';

export class TestModel extends BaseModel {
  branch_code: FormField;
  academic_year: FormField;
  // department: FormField;
 
  constructor() {
    super();
    this.branch_code = new FormField("", true, [Validations.EMPTY]);
    this.academic_year = new FormField("", true, [Validations.EMPTY]);
    // this.department = new FormField("", true, [Validations.EMPTY]);

  }
}

export class TestAddModel extends BaseModel {
  // branch_code: FormField;
  // academic_year: FormField;
  name: FormField;
  total_marks: FormField;
  // department: FormField;
 
  constructor() {
    super();
    // this.branch_code = new FormField("", true, [Validations.EMPTY]);
    // this.academic_year = new FormField("", true, [Validations.EMPTY]);
    this.name = new FormField("", true, [Validations.EMPTY]);
    this.total_marks = new FormField("", true, [Validations.EMPTY , Validations.DECIMAL]);
    // this.department = new FormField("", true, [Validations.EMPTY]);

  }
}


