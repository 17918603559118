import { FormField, FormService } from '../services/formService';
import { Validations, Validators } from '../services/validations';
import { BaseModel } from './baseModel';

export class ConcessionAssignmentModel extends BaseModel{
  branch_code: FormField;
  department_code: FormField;
  academic_year: FormField; 
  classroom: FormField;
  section:FormField;
  constructor() {
    super();
    this.branch_code = new FormField("", true, [Validations.EMPTY]);
    this.department_code = new FormField("", true, [Validations.EMPTY]);
    this.section = new FormField("", true, [Validations.EMPTY]);
    this.classroom = new FormField("", true, [Validations.EMPTY]);
    this.academic_year = new FormField("", true, [Validations.EMPTY]);
  }
}


export class AuthorizationConcessionModel extends BaseModel{
    branch_name: FormField;
    department_name: FormField;
    academic_year: FormField; 
    level_name: FormField;
    section:FormField;
    requested_on:FormField;
    requested_by:FormField;
    student_name:FormField;
    student_id:FormField;
    name: FormField;
    concession_in: FormField;
    value: FormField;
    constructor() {
      super();
      this.branch_name = new FormField("", true, [Validations.EMPTY]);
      this.department_name = new FormField("", true, [Validations.EMPTY]);
      this.section = new FormField("", true, [Validations.EMPTY]);
      this.level_name = new FormField("", true, [Validations.EMPTY]);
      this.academic_year = new FormField("", true, [Validations.EMPTY]);
      this.requested_on = new FormField("", true, [Validations.EMPTY]);
      this.requested_by = new FormField("", true, [Validations.EMPTY]);
      this.student_name = new FormField("", true, [Validations.EMPTY]);
      this.student_id = new FormField("", true, [Validations.EMPTY]);
      this.name = new FormField("", true, [Validations.EMPTY]);
      this.concession_in = new FormField("", true, [Validations.EMPTY]);
      this.value = new FormField("", true, [Validations.EMPTY]);
    }
  }
  export class ConcessionOptionModel extends BaseModel{
    concessionOptions: FormField;

    constructor() {
      super();
      this.concessionOptions = new FormField("Management", true, [Validations.EMPTY]);

    }
  }
